<template>
  <div style="background: #fff">
    <headers />
    <div class="head">
      <img src="@/assets/images/serviceBj.png" />
      <div class="headTit">服务权益</div>
      <!-- <div class="headTit1 mg35">查看不同服务权益的功能</div>
      <div class="headTit1">选择最适合您的权益查看不同服务权益的功能</div>
      <div class="headTit1">选择最适合您的权益</div> -->
    </div>

    <div class="content">
      <div class="headIconBox">
        <img src="@/assets/images/L1.png" />
        <img src="@/assets/images/L2.png" />
        <img src="@/assets/images/L3.png" />
        <img src="@/assets/images/L4.png" />
      </div>
      <div class="tableBox" style="background-color: #f3f3f3">
        <div class="tableL" style="color: #00161a">合作模式</div>
        <div class="tableR">
          <div class="tableRItem">L1级授权合作伙伴</div>
          <div class="tableRItem">L2级授权合作伙伴</div>
          <div class="tableRItem">L3级授权合作伙伴</div>
          <div class="tableRItem">L4级授权合作伙伴</div>
        </div>
      </div>

      <div
        class="tableBox"
        v-for="(item, index) in list"
        :key="index"
        :style="{ backgroundColor: index % 2 == 0 ? '#fff' : '#f3f3f3' }"
      >
        <div class="tableL">{{ item.name }}</div>
        <div class="tableR">
          <div class="tableRItem">
            <img
              v-if="String(item.classifyIds).includes(String(menuList[0].id))"
              src="@/assets/images/dui.png"
            />
            <img
              v-else
              src="@/assets/images/yuna.png"
            />
          </div>
          <div class="tableRItem">
            <img
              v-if="String(item.classifyIds).includes(String(menuList[1].id))"
              src="@/assets/images/dui.png"
            />
            <img
              v-else
              src="@/assets/images/yuna.png"
            />
          </div>
          <div class="tableRItem">
            <img
              v-if="String(item.classifyIds).includes(String(menuList[2].id))"
              src="@/assets/images/dui.png"
            />
            <img
              v-else
              src="@/assets/images/yuna.png"
            />
          </div>
          <div class="tableRItem">
            <img
              v-if="String(item.classifyIds).includes(String(menuList[3].id))"
              src="@/assets/images/dui.png"
            />
            <img
              v-else
              src="@/assets/images/yuna.png"
            />
          </div>
        </div>
      </div>
    </div>
    <footers />
  </div>
</template>

<script>
import headers from "@/components/header.vue";
import footers from "@/components/footer.vue";
import search from "@/components/search.vue";

import { equityClassify, equityList } from "@/api/lx";

export default {
  name: "Procucts",
  components: {
    headers,
    footers,
    search,
  },
  data() {
    return {
      menuList: [],
      list: [],
    };
  },
  created() {
    this.getEquityClassify();
  },
  mounted() {},
  methods: {
    getEquityClassify() {
      equityClassify({}).then((res) => {
        this.menuList = res.data;
        this.getList();
        // this.getSoftwareList();
      });
    },
    getList() {
      equityList({}).then((res) => {
        this.list = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.head {
  width: 100%;
  height: 300px;
  position: relative;
  overflow: hidden;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.headTit {
  width: 100%;
  text-align: center;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  line-height: 300px;
  font-size: 42px;
  color: #ffffff;
  // margin-top: 60px;
  position: relative;
  z-index: 2;
}
.headTit1 {
  width: 100%;
  text-align: center;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.9);
  line-height: 28px;
  position: relative;
  z-index: 2;
}
.mg35 {
  margin-top: 35px;
}
.page {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 65px;
}
.content {
  width: 1100px;
  background: #f9f9f9;
  border-radius: 82px 24px 0px 0px;
  border: 1px solid #e6edf0;
  margin: 0 auto;
  margin-top: 34px;
  margin-bottom: 113px;
  overflow: hidden;
  .headIconBox {
    width: 768px;
    display: flex;
    margin-left: 307px;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    img {
      display: block;
      width: 132px;
      height: 96px;
    }
  }
  .tableBox {
    width: 100%;
    display: flex;
    height: 60px;
  }
  .tableL {
    width: 307px;
    height: 100%;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #525f66;
    padding-left: 100px;
    box-sizing: border-box;
    line-height: 60px;
    font-weight: bold;
  }
  .tableR {
    width: 768px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tableRItem {
      width: 132px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 22px;
        height: 22px;
      }
    }
  }
}
</style>